import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from 'grommet';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useMutation } from '@apollo/client';

import InputText from '../Inputs/InputText';
import Loading from '../Loading';
import isBlank from '../../lib/isBlank';
import { identify } from '../../lib/analytics';
import { SUBSCRIBE_TO_MAILING_LIST } from '../../queries/mailingListQueries';
import { backdropApiClient } from '../../gatsby-theme-apollo/client';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required Field*'),
});

const FormCampaignEmailCapture = ({
  listId,
  properties,
  onSuccess,
  buttonLabel = 'Subscribe',
  ...rest
}) => {
  const [subscribe, { loading }] = useMutation(SUBSCRIBE_TO_MAILING_LIST, {
    client: backdropApiClient,
  });
  return (
    <Box {...rest}>
      {loading ? (
        <Box fill justify="center" align="center">
          <Loading size="large" />
        </Box>
      ) : (
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={async (values) => {
            const { data } = await subscribe({
              variables: {
                email: values.email,
                mailing_list_id: listId,
                properties,
              },
            });
            await identify({ email: values.email });
            if (onSuccess) {
              onSuccess(values, {
                subscriberCount:
                  data.createMailingListSubscription.subscriberCount,
                wasAlreadySubscribed:
                  data.createMailingListSubscription.wasAlreadySubscribed,
              });
            }
          }}
        >
          {({ isValid, handleSubmit }) => {
            return (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <Box
                  direction="row"
                  gap="small"
                  border={{ side: 'bottom', size: 'small' }}
                  fill="horizontal"
                >
                  <Box flex>
                    <Field
                      name="email"
                      type="email"
                      component={InputText}
                      placeholder="Email Address"
                      focusIndicator={false}
                      style={{ border: 'none' }}
                    />
                  </Box>
                  <Button
                    disabled={!isValid}
                    label={isBlank(buttonLabel) ? 'Submit' : buttonLabel}
                    type="submit"
                    plain
                    style={{ fontWeight: 500, opacity: isValid ? 1 : 0.4 }}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

FormCampaignEmailCapture.propTypes = {
  listId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  buttonLabel: PropTypes.string,
  properties: PropTypes.object,
};

export default FormCampaignEmailCapture;
